import { Link as RouterLink } from 'react-router-dom';

export function Link({ href, children, className = '' }: { href: string; children: React.ReactNode; className?: string }) {
  return (
    <RouterLink
      to={href}
      className={`text-gray-300 hover:text-white transition-colors ${className}`}
    >
      {children}
    </RouterLink>
  );
}