import { Link } from './Link';
import { ShoppingCart } from 'lucide-react';

export function Navbar() {
  return (
    <nav className="fixed top-0 w-full bg-black/95 backdrop-blur-sm z-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          {/* Logo */}
          <div className="flex items-center">
            <Link href="/" className="text-white font-bold text-xl">
            Holmgandr
            </Link>
          </div>

          {/* Navigation Links */}
          <div className="flex items-center space-x-8">
            <Link
              href="/"
              className="text-gray-300 hover:text-white transition-colors"
            >
              Home
            </Link>
            <Link
              href="/status"
              className="text-gray-300 hover:text-white transition-colors"
            >
              Status
            </Link>

            {/* Shop Link */}
            <a
              href="https://holmgandr.mysellix.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center space-x-2 text-gray-300 hover:text-white transition-colors"
            >
              <ShoppingCart className="h-5 w-5" />
              <span>Shop</span>
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
}
