import { Shield, Zap } from 'lucide-react';
import { ProductCard } from '../components/ProductCard';

export function Home() {
  const products = [
    {
      name: 'Moonclaw',
      description: 'Pixel/AI-based Metin2 bot platform.',
      features: [
        'Smart resource gathering',
        'Advanced Farmbot AI',
        'Anti-detection system',
        'Captcha bypass'
      ],
      imageUrl: '/moonclaw.jpg'
    },
    {
      name: 'Phantomforge',
      description: 'Memory-using, security-focused MMORPG bot platform.',
      features: [
        'Custom scripting support',
        'Multi-account management',
        'Security-focused design',
        'Memory-based operation'
      ],
      imageUrl: '/Phantomforge.jpg'
    }
  ];
  
  


  return (
    <div className="min-h-screen bg-gray-950">
      {/* Hero Section */}
      <div className="pt-24 pb-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto text-center">
          <h1 className="text-5xl font-bold text-white mb-6">
            Level Up Your Gaming Experience
          </h1>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            
Our platform provides high-quality automation tools tailored for MMORPG players and developers. Designed to simplify repetitive tasks and enhance gameplay efficiency, these tools ensure reliability and security for a seamless experience. Whether you are looking to streamline your in-game activities or develop custom automation solutions, we are committed to supporting your journey with trusted resources and expert guidance.
          </p>
        </div>
      </div>

      {/* Features */}
      <div className="py-16 bg-black/30">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-8">
            <div className="flex items-start space-x-4">
              <Shield className="h-8 w-8 text-gray-500 mt-1" />
              <div>
                <h3 className="text-xl font-semibold text-white mb-2">Undetectable Operation</h3>
                <p className="text-gray-400">Our bots use advanced techniques to remain undetected while providing seamless automation.</p>
              </div>
            </div>
            <div className="flex items-start space-x-4">
              <Zap className="h-8 w-8 text-gray-500 mt-1" />
              <div>
                <h3 className="text-xl font-semibold text-white mb-2">Lightning Fast Performance</h3>
                <p className="text-gray-400">Optimized code ensures minimal resource usage while maintaining peak performance.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Products */}
      <div className="py-16 px-4 sm:px-6 lg:px-8">
        <div className="max-w-7xl mx-auto">
          <h2 className="text-3xl font-bold text-white mb-12 text-center">Our Solutions</h2>
          <div className="grid md:grid-cols-2 gap-8">
            {products.map((product) => (
              <ProductCard key={product.name} {...product} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}