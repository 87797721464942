interface ProductCardProps {
  name: string;
  description: string;
  features: string[];
  imageUrl: string;
}

export function ProductCard({ name, description, features, imageUrl }: ProductCardProps) {
  return (
    <div className="bg-gray-900 rounded-xl overflow-hidden shadow-xl transform hover:scale-105 transition-transform duration-300">
      <div className="w-full h-[500px] relative">
        <img src={imageUrl} alt={name} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
      </div>
      <div className="p-6 -mt-20 relative z-10">
        <h3 className="text-2xl font-bold text-white mb-3">{name}</h3>
        <p className="text-gray-400 mb-4">{description}</p>
        <ul className="space-y-2">
          {features.map((feature, index) => (
            <li key={index} className="text-gray-300 flex items-center">
              <span className="mr-2">•</span>
              {feature}
            </li>
          ))}
        </ul>
        <a
          href="https://holmgandr.mysellix.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="mt-6 block w-full bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded-lg transition-colors text-center"
        >
          Purchase Now
        </a>
      </div>
    </div>
  );
}